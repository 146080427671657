import React from 'react';
import AppHeaderAuth from '../AppHeaderAuth/AppHeaderAuth';

import './AppHeader.css';
let users = ["daichi","hugo","Jun","Mark","Andrew","John"]
function chooseRandomUser() {
  return users[Math.floor(Math.random() * users.length)];
}
let user = chooseRandomUser();
window.pendo.initialize({

  visitor: {
      id:              user,   // Required if user is logged in
      checklist: ['1','9'],
      custom_1648627164610: false
      
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
  },

  account: {
      id:           'trial account', // Required if using Pendo Feedback
      plantype: 'trial'
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
  }
});
export default function AppHeader() {
  return (
    <header className='header'>
      <nav className='navbar navbar-expand-lg'>
        <a className='navbar-brand' href='/'>
          <img src='/images/microsoft-small.svg' className='navbar-logo' alt='Microsoft' />
        </a>
        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon'></span>
        </button>

        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'>
            <li className='nav-item'>
              <a className='nav-link' href='/Search'>Search</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='.'> more</a>
            </li>
          </ul>
        </div>
       
        {/* <AppHeaderAuth /> */}


      </nav>
      
    </header>
  );
};
